import axios from "axios";
import Vue from "vue";
import * as Sentry from "@sentry/vue";

Sentry.init({
  Vue,
  dsn: "https://c6809975644f4747b89a992269ab405d@o482137.ingest.sentry.io/4505356680626176",
  beforeSend(event, hint) {
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
});
console.log("Sentry initialized");

function getDataFromHost(host) {
  try {
    if (host !== "localhost" && host !== "plateforme.promo.dev") {
      return axios.get(
        process.env.VUE_APP_ROOT_API + "/api/v2/plateforms/host/" + host
      );
    }
  } catch (error) {
    Sentry.captureException(error);
    return;
  }
}

function getDataFromID(_id) {
  try {
    if (_id.length === 24) {
      return axios.get(
        process.env.VUE_APP_ROOT_API + "/api/v2/plateforms/" + _id
      );
    }
    return;
  } catch (error) {
    Sentry.captureException(err);
    return;
  }
}

export async function Init() {
  try {
    let hostdev = window.location.hostname;
    let test = false;

    if (hostdev === "plateforme.promo.dev" || hostdev === "localhosts") {
      test = true;
    }

    let path = window.location.pathname.split("/");
    let local_link = "";

    if (path[1].length === 24 && path.length >= 2) {
      var { data } = await getDataFromID(path[1]);
      local_link = `/${data._id}`;
    } else {
      let host = hostdev;
      host = host.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0];
      var { data } = await getDataFromHost(host);
    }

    if (!data) {
      window.location.href = "/404";
    }
    data.test = test;
    data.local_link = local_link;

    let def = {
      custom: {
        fontSize: "16px",
        fontFamily: "system-ui",
        bodyBackground: "#FFF",
        bodyText: "#444",
        primaryBackground: "#FF6600",
        primaryText: "#FFF",
        secondaryBackground: "#E5E7EB",
        secondaryText: "#444",
      },
      logo: "/logo.png",
      title: "Titre",
      description: "Description",
      favicon: "/favicon.png",
      files: [],
      externalLinks: [
        {
          name: "Site Allopneus",
          uri: "https://www.allopneus.com/?utm_source=facility&utm_medium=site&utm_campaign=plateforme_promo",
        },
      ],
      customHow: false,
    };

    let operations = [];

    if (data.operations && data.operations.length > 0) {
      for (let index = 0; index < data.operations.length; index++) {
        if (
          data.operations[index].mode === "EXTERNAL" ||
          data.operations[index].mode === "FORM" ||
          data.operations[index].mode === "GABARIT" ||
          data.operations[index].mode === "INTERNAL"
        ) {
          let op = {};

          if (data.operations[index].mode != "EXTERNAL") {
            op = await axios.get(
              `${process.env.VUE_APP_ROOT_API}/api/v2/plateforms/operation/${data.operations[index].operationId}`,
              { withCredentials: true }
            );

            op = op.data[0];
          } else {
            op = data.operations[index];
            op.date_debut = op.dateStart;
            op.date_fin = op.dateEnd;
          }

          op.mode = data.operations[index].mode;

          if (op.files) {
            if (!data.operations[index].logo) {
              var logo = op.files.filter((s) => s.filename.startsWith("logo1"));
              if (logo && logo.length > 0) {
                op.logo = logo[0].uri;
              }
            } else {
              op.logo = data.operations[index].logo;
            }

            if (!data.operations[index].banner) {
              var banner = op.files.filter((s) =>
                s.filename.startsWith("banner.")
              );
              if (banner && banner.length > 0) {
                op.banner = banner[0].uri;
              }
            } else {
              op.banner = data.operations[index].banner;
            }

            if (!data.operations[index].banner) {
              var banner = op.files.filter((s) =>
                s.filename.startsWith("bannerbig.")
              );
              if (banner && banner.length > 0) {
                op.banner = banner[0].uri;
              }
            } else {
              op.bannerbig = data.operations[index].bannerbig;
            }

            var modalites = op.files.filter(
              (s) =>
                s.filename.startsWith("modalites") ||
                s.filename.startsWith("Modalites")
            );
            if (modalites && modalites.length > 0) {
              op.modalites = modalites[0].uri;
            }

            var reglement = op.files.filter(
              (s) =>
                s.filename.startsWith("reglement") ||
                s.filename.startsWith("Reglement")
            );
            if (reglement && reglement.length > 0) {
              op.reglement = reglement[0].uri;
            }
          }

          if (op) {
            if (data.operations[index].testMode) op.testMode = true;
            operations.push(op);
          }
        }
      }
    }

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let filteredOldOp = operations.filter(function (op) {
      return new Date(op.date_fin).getTime() <= today.getTime();
    });

    let filteredCurrentOp = operations.filter(function (op) {
      const startDate = new Date(op.date_debut);
      startDate.setHours(0, 0, 0, 0);
      if (new Date(op.date_fin).getTime() >= today.getTime()) {
        console.log("op", op);
      }
      return (
        (startDate.getTime() <= today.getTime() &&
          new Date(op.date_fin).getTime() >= today.getTime()) ||
        (op.testMode && data.test)
      );
    });

    if (filteredOldOp) {
      filteredOldOp.sort(
        (a, b) =>
          new Date(b.date_fin).getTime() - new Date(a.date_fin).getTime()
      );
      data.filteredOldOp = filteredOldOp || {};
    }
    if (filteredCurrentOp) data.filteredCurrentOp = filteredCurrentOp || {};

    let mb = { ...data };

    if (mb.files && mb.files.length > 0) {
      let tmp_logo = mb.files.filter((s) => s.filename.startsWith("logo"));
      if (tmp_logo && tmp_logo.length > 0) mb.logo = tmp_logo[0].uri;

      let tmp_favicon = mb.files.filter((s) =>
        s.filename.startsWith("favicon")
      );
      if (tmp_favicon && tmp_favicon.length > 0)
        mb.favicon = tmp_favicon[0].uri;

      if (mb.favicon) {
        var link = document.querySelector("link[rel='icon']");
        link.href = mb.favicon;
      }

      let tmp_politique = mb.files.filter((s) =>
        s.filename.startsWith("politique")
      );
      if (tmp_politique && tmp_politique.length > 0)
        mb.politique = tmp_politique[0].uri;

      let tmp_mentions = mb.files.filter((s) =>
        s.filename.startsWith("mentions")
      );
      if (tmp_mentions && tmp_mentions.length > 0)
        mb.mentions = tmp_mentions[0].uri;

      let tmp_cookie = mb.files.filter((s) =>
        s.filename.startsWith("politique")
      );
      if (tmp_cookie && tmp_cookie.length > 0) mb.cookies = tmp_cookie[0].uri;
    }

    var link = document.querySelector("link[rel='icon']");
    link.href = mb.favicon;
    window.document.title = mb.title.replace(/(<([^>]+)>)/gi, ""); // BALISE TITLE ONT SHOOT LE HTML

    if (
      mb.custom.fontFamily != "Exo" &&
      mb.custom.fontFamily != "Open Sans" &&
      mb.custom.fontFamily != "Roboto" &&
      mb.custom.fontFamily != "system-ui"
    ) {
      var head = document.getElementsByTagName("head")[0];
      var link = document.createElement("link");
      link.id = mb.fontFamily;
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href =
        "https://fonts.googleapis.com/css?family=" +
        mb.custom.fontFamily +
        ":wght@300;400;500;700;900";
      link.media = "all";
      head.appendChild(link);
    }

    const style = document.createElement("style");
    style.appendChild(
      document.createTextNode(
        `:root {
            --font-size : ${mb.custom.fontSize}px;
            --font-family: "${mb.custom.fontFamily}", sans-serif;   
            --font-color : ${mb.custom.bodyText};   
            --background-color : ${mb.custom.bodyBackground};  
            --text-primary-color : ${mb.custom.primaryText};   
            --bg-primary-background : ${mb.custom.primaryBackground};  
            --text-secondary-color : ${mb.custom.secondaryText};   
            --bg-secondary-background : ${mb.custom.secondaryBackground};
            --fields-rounded : ${mb.custom.border_radius_fields}px;
            --buttons-rounded : ${mb.custom.border_radius_buttons}px;
        }`
      )
    );

    document.head.appendChild(style);

    return mb;
  } catch (error) {
    Sentry.captureException(error);
  }
}
